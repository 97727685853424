import React from "react";
import { Route, Routes } from "react-router-dom";
import Home from "../Home";
import Pool from "../Pool";
import Navigation from "../../components/Navigation";

function App(props) {
  return (
    <>
      <Navigation />
      <Routes>
        <Route path="/" element={<Home />}></Route>
        <Route path="/pool" element={<Pool />}></Route>
      </Routes>
    </>
  );
}

export default App;
