import React from "react";
import { Link } from "react-router-dom";
import "./Navigation.scss";

function Navigation(props) {
  return (
    <div className="navigation">
      <ul>
        <li>
          <Link to="/">Home</Link>
        </li>
        <li>
          <Link to="/pool">Pool</Link>
        </li>
      </ul>
    </div>
  );
}

export default Navigation;
