import React, { useEffect, useState } from "react";
import "./Pool.scss";

import {
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

import RadialSeparators from "./RadialSeparators";

function Pool() {
  const [poolCapacity, setPoolCapacity] = useState({
    current: "0",
    capacity: "0",
  });
  const [percentage, setPercentage] = useState(0);

  async function getData() {
    const response = await fetch(
      `${window.location.protocol}//${window.location.hostname}:8555/pool.php`,
    );
    const data = await response.json();

    setPoolCapacity(data);
  }

  useEffect(() => {
    percentCapacity();
  }, [poolCapacity.current, poolCapacity.capacity]);

  useEffect(() => {
    getData().catch((e) => console.log(e));
  }, []);

  // console.log("-> poolCapacity", poolCapacity);

  const percentCapacity = () => {
    setPercentage(
      Math.ceil(
        (parseInt(poolCapacity.current) * 100) /
          (parseInt(poolCapacity.current) + parseInt(poolCapacity.capacity)),
      ),
    );
  };

  return (
    <div className="pool">
      <h1>Phönix-Bad capacity</h1>
      <CircularProgressbarWithChildren
        value={percentage | 0}
        strokeWidth={10}
        styles={buildStyles({
          strokeLinecap: "round",
        })}
      >
        <div className="data">
          <div className="percent">
            {percentage | 0}
            <sup>%</sup>
          </div>
          <div className="comment">Free: {poolCapacity?.capacity}</div>
          <div className="comment">Current: {poolCapacity?.current}</div>
        </div>
        <RadialSeparators
          count={60}
          style={{
            background: "#fff",
            width: "2px",
            // This needs to be equal to props.strokeWidth
            height: `${20}%`,
          }}
        />
      </CircularProgressbarWithChildren>
    </div>
  );
}

export default Pool;
